.estatistica {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 250px;
  min-height: 200px;
}

.estatisticaValue {
  color: #005c8b;
  font-size: 42px;
  font-weight: bold;
}

.estatisticaLabel {
  font-family: "Inter";
  font-size: 16px;
  text-align: center;
}
