.conteudo {
  margin-top: 20px;
}

.searchContainer {
  background: #f2f2f2;
  border-radius: 25px;
}

@media (min-width: 992px) {
  .logo {
    width: 160px !important;
    margin-top: 30px !important;
  }
}
