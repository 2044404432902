.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  max-width: 300px;
}

.list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-evenly;
  width: 100%;
}

.listItem {
  font-family: "Questrial";
  font-size: 22px;
}

.listItemLink {
  color: #253868;
}

.logo {
  width: 211px;
}

@media only screen and (min-width: 500px) {
  .container {
    max-width: 600px;
  }

  .list {
    gap: none;
  }
}
