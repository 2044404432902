.container {
  text-align: center;
  background: #005c8b;
}

.content {
  font-family: "Inter";
  color: #ffffff;
  padding: 15px;
}
