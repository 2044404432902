.estatistica {
  align-items: center;
  color: #005c8b;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f2f2f2;
  min-width: 250px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.estatisticaValue {
  font-size: 42px;
  font-weight: bold;
  padding: 10px;
}

.estatisticaLabel {
  color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  text-align: center;
  background-color: #005C8B;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  padding: 10px;
}
