.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
}

@media only screen and (min-width: 600px) {
  .container {
    flex-direction: row;
    justify-content: center;
    width: unset;
  }
}

@media only screen and (min-width: 1034px) {
  .container {
    flex-direction: column;
  }
}
