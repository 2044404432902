.container {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  max-width: 400px;
  gap: 15px;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.message {
  font-size: 16px;
}
