.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.questionView {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}

.questionLeftView {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
}

.questionLeftViewTexts {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 50px;
}

.teste {
  align-items: flex-start;
  display: flex;
  gap: 10px;
}

.questionRightView {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
}

.questionTitle {
  font-family: "Questrial";
  font-size: 25px;
  font-weight: 600;
  color: #005c8b;
}

.questionType {
  text-transform: uppercase;
  font-size: 16px;
  color: #333;
}

.goBackIcon {
  font-size: 30px !important;
  color: #000000 !important;
  opacity: 0.5 !important;
}

.questionMainInfo {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

@media only screen and (min-width: 600px) {
  .container {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .questionRightView {
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .questionView {
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .questionRightView {
    align-items: flex-start;
    flex-direction: row;
    max-width: unset;
  }
}

@media only screen and (min-width: 1033px) {
  .questionView {
    align-items: flex-start;
  }

  .questionRightView {
    gap: 50px;
  }
}
