.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 25px;
  margin-right: 25px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 50px;
}

.cardImage {
  max-width: 100%;
  height: auto;
}

.cardTitle,
.links {
  color: #253868;
  font-family: "Questrial";
  font-size: 24px;
  font-weight: bold;
}

.cardDescription {
  color: #253868;
  font-family: "Inter";
  font-size: 16px;
}

.links {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.linksContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.link a {
  font-family: "Inter";
  color: #005c8b;
  font-size: 14px;
}

@media only screen and (min-width: 1024px) {
  .card {
    flex-direction: row;
    max-width: 1080px;
  }

  .cards .card:nth-child(2) {
    flex-direction: row-reverse;
  }

  .cardImage {
    max-width: 100%;
    height: auto;
  }
}
