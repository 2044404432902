.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.loadMoreButton {
  text-align: center;
  margin-bottom: 25px;
}

.endMessage {
  color: #4F4F4F;
  font-family: "Questrial";
  font-size: 24px;
  margin-top: 50px;
  align-self: center;
  margin-bottom: 25px;
  text-align: center;
}
