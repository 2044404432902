.loadingAnimation {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.animation {
  width: 400px;
}

.animationMessage {
  font-family: 'Questrial';
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  color: #005c8b;
}
