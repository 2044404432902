.container {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  will-change: transform;
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.buttons {
  justify-content: center;
  background-color: #005C8B;
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  gap: 25px;
  width: 100%;
  padding: 5px;
}

.button {
  color: #f2f2f2;
}

.disabledButton {
  opacity: 0.5 !important;
}

.title {
  font-family: "Questrial";
  font-weight: 500;
  font-size: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #f2f2f2;
  background-color: #666666;
  width: 100%;
  text-align: center;
  padding: 5px;
}
