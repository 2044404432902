.searchBarContainer {
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 25px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: #005c8b;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
  max-width: 800px;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.selects {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}

.select {
  width: 100%;
}

.palavrasChaveInput {
  width: 100%;
}

@media only screen and (min-width: 500px) {
  .selects {
    flex-direction: row;
  }

  .select {
    width: 50%;
  }
}

@media only screen and (min-width: 600px) {
  .searchBarContainer {
    gap: 25px;
    flex-direction: row;
    padding: 30px;
  }
}

@media only screen and (min-width: 880px) {
  .searchBarContainer {
    margin-left: auto;
    margin-right: auto;
  }
}
