.container {

}

.documentController {
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #005C8B;
}

.documentContainer {
  display: flex;
  justify-content: center;
  overflow: auto;
  max-width: 800px;
}

.page {
  display: inline-block;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
