.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  padding: 25px;
}

.resultCountTitle {
  font-family: "Questrial";
  font-size: 24px;
  color: #005c8b;
}

.selectFilter {
  height: 30px;
  width: 155px;
  border-radius: 10px;
}

.questions {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-evenly;
}

.question {
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 10px;
  cursor: pointer;
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0.025);
}

.question:hover {
  color: #fff !important;
  transition: all 0.3s;
  background: #005c8b !important;
}

.question:hover .questionTitle {
  color: #fff !important;
  transition: all 0.3s;
}

.question:hover .questionDescription {
  color: #fff !important;
  transition: all 0.3s;
}

.question:hover .questionSeeMore {
  color: #fff !important;
  transition: all 0.3s;
}

.question:hover .questionPart {
  color: #fff !important;
  transition: all 0.3s;
}

.question:hover .questionType {
  color: #fff !important;
  transition: all 0.3s;
}

.questionTitle {
  color: #005c8b;
  font-family: "Inter";
  font-size: 20px;
  font-weight: bold;
}

.questionPart {
  font-family: "Inter";
  font-size: 18px;
}

.questionType {
  font-family: "Inter";
  font-size: 18px;
}

.questionDescription {
  color: #666;
  font-family: "Inter";
  font-weight: 200;
  overflow-wrap: break-word;
}

.questionSeeMore {
  align-self: flex-end;
  color: #f29559;
  font-size: 14px;
}

@media only screen and (min-width: 450px) {
  .questions {
    align-items: flex-start;
    flex-direction: row;
  }
}

@media only screen and (min-width: 675px) {
  .question {
    width: 300px;
  }
}

@media only screen and (min-width: 875px) {
  .question {
    width: 400px;
  }
}

@media only screen and (min-width: 1080px) {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media only screen and (min-width: 1325px) {
  .container {
    padding-top: 40px;
    padding-left: 150px;
    padding-right: 150px;
  }

  .question {
    width: 500px;
  }
}
