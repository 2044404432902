.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.openInNewLink {
  color: #000000;
  opacity: 0.5;
  text-decoration: none;
}

.pdfControllerIcon {
  color: #f2f2f2 !important;
}

.disabledIcon {
  opacity: 0.5 !important;
}
