.container {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  min-width: 250px;
}

.title {
  font-size: 20px;
  color: #f2f2f2;
  background-color: #005C8B;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  width: 100%;
  text-align: center;
}

.gabarito {
  font-weight: 500;
  font-size: 26px;
  color: #212529;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  width: 100%;
  text-align: center;
}
